@import '../../../../common_styles/colors';

.step-header {
  display: flex;
  align-items: center;
  margin: 48px 0 24px;

  .description {
    font-weight: 300;
    max-width: 600px;
    margin-top: 4px;
    color: $color-semi-dark-grey;
    font-style: italic;
  }

  .circle {
    width: 32px;
    height: 32px;
    border: 2px solid $color-red-main;
    color: $color-red-main;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 12px;
    line-height: 1;

    &.done {
      background: $color-red-main;
      color: white;
    }

    span {
      margin: 0 auto;
    }
  }
}