@import '../../../../../../common_styles/colors';

.shadows-container {
  overflow-y: auto;
  flex: 1;

  .shadows-wrapper {
    margin: -8px;
    display: flex;
    flex-wrap: wrap;
    padding: 16px;
  }

  .shadow-wrapper {
    width: calc(25% - 16px);
    height: 150px;
    margin: 8px;
    padding: 8px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    flex-wrap: wrap;
    border-radius: 4px;
    transition: .3s;

    img {
      max-width: 100%;
      max-height: calc(100% - 45px);
    }

    h4 {
      font-weight: lighter;
      width: 100%;
      text-align: center;
      text-transform: lowercase;
      line-height: 1.5;
    }

    &:hover {
      box-shadow: 0 0 6px 0 rgba(black, 0.2);
    }
  }
}
