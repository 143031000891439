.board {
  position: relative;
}

.canvas-container {
  width: 100%;
  height: 100%;
}

canvas {
  width: 100%;
  height: 100%;
}