@import '../../../../common_styles/colors';

$workspace-height: 600px;
$workspace-width: 500px;
$shadows-container-width: 800px;

$border-box: 0 0 5px 0 rgba(black, 0.2);

.workspace {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 16px 0;

  .board {
    width: $workspace-width;
    height: $workspace-height;
    margin-right: 8px;
  }

  .shadows-container {
    height: $workspace-height;
    width: 100%;
    //box-shadow: $border-box;
    margin-left: 4px;
  }

  .board, .shadows-container {
    border-radius: 4px;
  }
}

.back-button.root {
  margin: 32px 0 16px;
}

.offer-info {
  font-size: 12px;
  margin-top: 120px;
  max-width: 400px;
  color: grey;
}