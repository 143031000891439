@import '../../common_styles/colors';

.choice-tile {
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  padding: 16px;
  transition: .3s;
  border: 2px solid transparent;

  &:hover {
    box-shadow: 0 0 6px 0 rgba(black, 0.2);
  }

  &.active {
    border: 2px solid $color-red-main;
  }

  .image {
    padding: 8px;
    min-height: 250px;

    img {
      max-height: 250px;
    }
  }
}