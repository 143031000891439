.summary {
  margin-top: 40px;
  padding: 16px;

  .summary-row {
    display: flex;
    justify-content: space-between;
  }

  p {
    margin-bottom: 16px;
    line-height: 1.4;
  }

  .price {
    font-size: 20px;
  }
}
