.row-wrapper {
  display: flex;
  justify-content: center;
}

.color-picker {
  width: 200px;
  height: 200px;
  border: none;
  background: none;
  cursor: pointer;

  &:hover {
    box-shadow: 5px 5px 20px rgba(black, 0.2);
  }
}