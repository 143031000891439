@import "../../common_styles/colors";

.header-wrapper {
  border: none;
  top: 0;
  width: 100%;
  z-index: 10;
  background: white;
  border-bottom: $color-red-main 2px solid;
  padding: 8px 0;
}

.header {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  .back-button {
    display: flex;
    align-items: center;
  }

  .header-title {
    h3 {
      letter-spacing: 3px;
      text-transform: uppercase;
    }
  }
}

.header-media-wrapper {
  height: 60px;
}

.header-media {
  height: 100%;
}
