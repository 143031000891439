@import './reset.scss';
@import "./colors";


body, html {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;
  color: $color-text;
}

.c-container__default {
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;

  @media all and (max-width: 1000px) {
    padding: 0 16px;
  }
}

.c-container__small {
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  max-width: 800px;
}

.bold {
  font-weight: bold;
}

.light {
  font-weight: 300;
}

.right-align {
  text-align: end;
}

.block {
  display: block;
}