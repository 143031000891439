@import '../../common_styles/mixins';

.mobile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 16px;

  h3, h4 {
    font-weight: lighter;
  }

  @include bp-medium {
    display: none;
  }
}