// Colors
$color-white: #fff;
$color-black: #000;
$color-grey: #ccc;
$color-light-grey: #e7e7e7;
$color-lighter-grey: #f5f5f5;
$color-dark-grey: #494949;
$color-semi-dark-grey: #535353;

$color-red-main: #e33712;

// Text
$color-bg: $color-white;
$color-text: $color-dark-grey;

// Links
$color-link: $color-text;

// Borders
$color-border: $color-grey;
